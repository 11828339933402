import instance from "../api/axios.api";

const ConfigService = {

    async runCommand(options) {
        const { data } = await instance.post("/config/run-command", {...options});
        return data;
    },

};
export default ConfigService;
