import { Button } from "@mui/material";
import React, {useContext, useState} from "react";
import { UserContext } from "../../providers/UserProvider";
import { useUserRedirect } from "../../utils/useUserRedirect";
import useStyles from "./styles";
import useGStyles from "../../assets/global-styles";
import clsx from "clsx";
import {useNavigate} from "react-router";
import ChartFrame from "./ChartFrame"

const Chart = () => {

    const classes = useStyles();
    const gClasses = useGStyles();
    const { user, setUser } = useContext(UserContext);

    const nav = useNavigate();

    //если пользователь не авторизован редирект в "/auth/login"
    useUserRedirect();
    if(!user) return null;

    console.log("re-render Chart");

    return (
        <ChartFrame user={user}/>
    );
};

export default Chart;
