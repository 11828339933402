import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    BarSeries,
    CandlestickSeries,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    withDeviceRatio,
    withSize,
    LineSeries,
    MACDSeries,
    BollingerSeries,
    LabelAnnotation,
    SvgPathAnnotation,
    ScatterSeries,
    InteractiveText,
    Label,
    Annotate,
    StraightLine,
    //CircleMarkerSeries,
    RSISeries,
    TrendLine,
    HoverTooltip,
} from "react-financial-charts";
import OHLCTooltip from "./OHLCTooltip";
import useStyles from "./styles.js";
import useGStyles from "../../assets/global-styles";
import DataService from "../../services/data.service";
import UIService from "../../services/ui.service.js";
import moment from "moment";
import {GlobalStateContext} from "../../providers/GlobalStateProvider";
import CircleIcon from '@mui/icons-material/Circle';

//let initialDataVar = {data: [], loaded: false};
let initialDataVar = [];

//стили временой шкалы
const axisStyles = {
    strokeStyle: "#383E55", // Color.GRAY
    strokeWidth: 2,
    tickLabelFill: "#9EAAC7", // Color.LIGHT_GRAY
    tickStrokeStyle: "#383E55",
    gridLinesStrokeStyle: "rgba(56, 62, 85, 0.5)" // Color.GRAY w Opacity
};

const coordinateStyles = {
    fill: "#383E55",
    textFill: "#FFFFFF"
};

const zoomButtonStyles = {
    fill: "#383E55",
    fillOpacity: 0.75,
    strokeWidth: 0,
    textFill: "#9EAAC7"
};

const crossHairStyles = {
    strokeStyle: "#9EAAC7"
};

const bollingerBandsIndentation = 0.0001;

const wsUrl = "ws://localhost";
const wsPort = 3080;

let xScale, xAccessor, displayXAccessor, min, max, xExtents;

let tlSupportH = [0,0,0,0,0,0,0,0,0,0];
let tlResistanceH = [0,0,0,0,0,0,0,0,0,0];

// Default green/red colors for candlesticks
const openCloseColor = (d) => (d.close > d.open ? "#26a69a" : "#ef5350");

// yExtentsCalculator: used from updating price series
// https://github.com/react-financial/react-financial-charts/blob/main/packages/stories/src/features/updating/BasicLineSeries.tsx#L55
//вычисляет диапазон значений по оси Y
//принимает бары в видимой области
const yExtentsCalculator = ({ plotData }) => {

    let min;
    let max;
    for (const { low, high } of plotData) {
        if (min === undefined) min = low;
        if (max === undefined) max = high;
        if (low !== undefined && min > low) min = low;
        if (high !== undefined && max < high) max = high;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};

const yExtentsCalculatorBB = ({plotData}) => {

    let min;
    let max;
    for (const { techAnalysis } of plotData) {
        const {lower, upper} = techAnalysis.BollingerBands;
        if (min === undefined) min = lower;
        if (max === undefined) max = upper;
        if (lower !== undefined && min > lower) min = lower;
        if (upper !== undefined && max < upper) max = upper;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};

const yExtentsCalculatorMACD = ({plotData}) => {

    let min;
    let max;
    for (const { techAnalysis } of plotData) {
        const lower = techAnalysis.MACD.MACD;
        const upper = techAnalysis.MACD.MACD;
        if (min === undefined) min = lower;
        if (max === undefined) max = upper;
        if (lower !== undefined && min > lower) min = lower;
        if (upper !== undefined && max < upper) max = upper;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};

const yExtentsCalculatorRSINorm = ({plotData}) => {

    let min;
    let max;
    for (const { normRSI } of plotData) {
        const lower = normRSI;
        const upper = normRSI;
        if (min === undefined) min = lower;
        if (max === undefined) max = upper;
        if (lower !== undefined && min > lower) min = lower;
        if (upper !== undefined && max < upper) max = upper;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};




const yExtentsCalculatoRSI = ({plotData}) => {

    let min;
    let max;
    for (const { techAnalysis } of plotData) {
        const lower = techAnalysis.RSI;
        const upper = techAnalysis.RSI;
        if (min === undefined) min = lower;
        if (max === undefined) max = upper;
        if (lower !== undefined && min > lower) min = lower;
        if (upper !== undefined && max < upper) max = upper;
    }
    if (min === undefined) min = 0;
    if (max === undefined) max = 0;

    const padding = (max - min) * 0.1;
    return [min - padding, max + padding * 2];
};

const FinancialChart = (props) => {

    const {
        timeDisplayFormat,
        height,
        margin,
        priceDisplayFormat,
        ratio,
        width,
        user,
        gridHeight,
    } = props;

    const classes = useStyles();
    const gClasses = useGStyles();

    //глобальный state
    const {
        tFrame, setTFrame,
        bottomGraph, bottomGraphIndicator,
        backdrop, setBackdrop,
        activeStrategy, setActiveStrategy,
        testStrategyRange, setTestStrategyRange,
        toast, setToast,
    } = useContext(GlobalStateContext);


    const ws = useRef(null);

    //WS
    const [wsIsPaused, setWsIsPaused] = useState(false);
    const [wsStatus, setWsStatus] = useState("");
    const [predictData, setPredictData] = useState([]);
    const [activeHoverTooltipRate, setActiveHoverTooltipRate] = useState(null);

    const [chartData, setChartData] = useState({
        xScale: null,
        xAccessor: () => {},
        displayXAccessor: null,
        xExtents: [],
        data: [],
        loaded: false,
    });


    const updateDataAndParams = (newData) => {

        const xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d && d.date);

        const values = xScaleProvider( newData || []);
        const dataTmp = values.data || [];
        const xAccessor = values.xAccessor;

        min = xAccessor(dataTmp[Math.max(0, dataTmp.length - parseInt(width / 5))]);
        max = xAccessor(dataTmp[dataTmp.length - 1]);

        //сохраняем data в state
        setChartData({
            xScale: values.xScale,
            xAccessor,
            displayXAccessor: values.displayXAccessor,
            xExtents: [min, max + 1],
            data: dataTmp,
            loaded: true,
        });

    };

    //ws
    useEffect(() => {

        if (!wsIsPaused) {

            try{
                //console.log("create WS on: "+wsUrl+":"+wsPort);
                ws.current = new WebSocket(wsUrl+":"+wsPort+"?ws=1&type=updateChart&userId="+user._id+"&tFrame="+tFrame);
                ws.current.onopen = () => setWsStatus("open");
                ws.current.onclose = () => setWsStatus("close");

            }catch (e) {
                console.log(e.message);
            }

            gettingWsData();
        }

        return () => ws.current.close(); // кода меняется isPaused - соединение закрывается

    }, [ws, wsIsPaused, activeStrategy]);

    useEffect(() => {

        setChartData({...chartData, loaded: false});
        setBackdrop(true);

        if(activeStrategy.status == "loading"){
            //initialDataVar = [];
            //updateDataAndParams(initialDataVar);
            return;
        }

        if(activeStrategy && activeStrategy.status && activeStrategy.status == "active" && activeStrategy.rates?.length){
            console.log("Cтратегия активна - загружаем ее данные в Chart");

            initialDataVar = activeStrategy.rates.map(r => ({...r, date: moment(r.date).toDate()})) || [];
            updateDataAndParams(initialDataVar);

            setBackdrop(false);

        }
        if(["loading", "active"].indexOf(activeStrategy.status) < 0){
            console.log("стратегия не активна - загружаем данные графика в Chart");

            DataService.getRates({tFrame}).then((res, err) => {

                setBackdrop(false);
                console.log("DataService.getRates() загружено: ",res?.rates?.length);
                const error = (err || !(res && res.status && res.rates)) && (res?.error || "Неизвестная ошибка!");
                //if(error) return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});
                if(error){
                    console.error(error);
                    return
                }

                //отправляем на сервер информацию о том, что у нас смениолся tFrame
                ws.current.send(JSON.stringify({type: "chart", data: {action: "tFrameChange", tFrame}}));

                initialDataVar = res.rates.map(r => ({...r, date: moment(r.date).toDate()})) || [];
                updateDataAndParams(initialDataVar);

            });
        }


    }, [tFrame, activeStrategy.status]);

    const handleKeyDown = (event) => {

        if(!activeHoverTooltipRate || !event.ctrlKey){
            return;
        }

        if(event.key && event.key != "Control" && !(activeStrategy && activeStrategy.status && activeStrategy.status == "active")){
            setToast({open: true, severity: "error", closeTime: 2000, text: "Стратегия на активна!"});
            return;
        }

        switch (event.key) {
            case "1":
                setTestStrategyRange({...testStrategyRange, startDate: moment(activeHoverTooltipRate.date).seconds(0)});
                setToast({open: true, severity: "success", closeTime: 2000, text: "startDate: "+moment(activeHoverTooltipRate.date).format("DD.MM.YY HH:mm")});
                break;
            case "2":
                setTestStrategyRange({...testStrategyRange, endDate: moment(activeHoverTooltipRate.date).seconds(0)});
                setToast({open: true, severity: "success", closeTime: 2000,  text: "endDate: "+moment(activeHoverTooltipRate.date).format("DD.MM.YY HH:mm")});
                break;

            case "x":

                //console.log("activeHoverTooltipRate=", activeHoverTooltipRate);

                if(!(activeHoverTooltipRate.strategy?.buy || activeHoverTooltipRate.strategy?.sale)){
                    return;
                }

                initialDataVar = initialDataVar.map(r => (r._id == activeHoverTooltipRate._id?{...r, strategy: {...r.strategy, loss: !r.strategy.loss}}:r));
                updateDataAndParams(initialDataVar);

                const loss = initialDataVar.find(r => r._id == activeHoverTooltipRate._id)?.strategy?.loss || false;

                UIService.saveTestItem({testId: activeStrategy.testId, rateId: activeHoverTooltipRate._id, loss}).then((res, err) => {

                    const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
                    if(error) {
                        return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});
                    }

                    setToast({open: true, severity: "success", text: "Сохранено!"});

                });

                break;

            default:
                return;
        }
    };

    useEffect(() => {

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [activeStrategy, activeHoverTooltipRate]);

    const gettingWsData = useCallback(() => {

        if (!ws.current) return;

        ws.current.onmessage = (e) => {

            //подписка на получение данных по вебсокету
            if (wsIsPaused) return;
            const wsData = JSON.parse(e.data);
            //console.log("WS/app onmessage:");
            //console.log("wsData:", wsData);

            if(!initialDataVar?.length) return;


            //при активной стратегии или при ее загрузке выключаем обновление
            if(activeStrategy && activeStrategy.status && ["active", "loading"].indexOf(activeStrategy.status) > -1){
                console.log("activeStrategy.status="+activeStrategy.status+" выход");
                return;
            };

            let date;
            switch (wsData.action) {
                case "newRate":

                    date = moment(wsData.rate0.date).toDate();

                    //console.log("newRate, time"+moment(date).format("DD.MM.YY HH:mm:ss")+" close="+wsData.rate0.close.toFixed(4)+" bclose="+ wsData.rate0.techAnalysis.linearRegressionCandlesFromuGurvuTV.close.toFixed(4));

                    //console.log("Добавляю новый бар, time"+moment(date).format("DD.MM.YY HH:mm:ss")+" time="+moment.unix(wsData.rate0.time).format("DD.MM.YY HH:mm:ss") );
                    //предвариательно убираем самый старый бар
                    initialDataVar = initialDataVar.slice(1).concat({...wsData.rate0, date});
                    updateDataAndParams(initialDataVar);

                    break;

                case "updateRate":

                    date = moment(wsData.rate0.date).toDate();

                    //console.log("updateRate, time"+moment(date).format("DD.MM.YY HH:mm:ss")+" close="+wsData.rate0.close.toFixed(4)+" bclose="+ wsData.rate0.techAnalysis.linearRegressionCandlesFromuGurvuTV.close.toFixed(4));

                    //console.log("Обновляю активный бар, time"+moment(date).format("DD.MM.YY HH:mm:ss")+" time="+moment.unix(wsData.rate0.time).format("DD.MM.YY HH:mm:ss") );
                    initialDataVar = initialDataVar.slice(0,initialDataVar.length-1).concat({...wsData.rate0, date});
                    updateDataAndParams(initialDataVar);

                    break;

                case "updatePredict":
                    //setPredictData(wsData.predictData);

                    //const predictData = wsData.predictData.map(p => ({...p, date: moment(p.date).toDate()}));

                    //initialDataVar = initialDataVar.filter(d => !d.isPredictItem).concat(predictData);
                    //updateDataAndParams(initialDataVar);

                break;

            }

        };
    }, [wsIsPaused, activeStrategy]);

    if (!height || !ratio || !width || !chartData.data?.length || !chartData.loaded){
        return null;
    }


    const labelAnnotation = {
        fontSize: 14,
        fill: 'white',
        //textFill: 'yellow',
        text: "Monday",
        //y задается в пунктах
        y: ({ yScale, datum }) => yScale(datum.high+0.00002),
    };


    const svgBuy = {
        fill: "#175CE3",
        path: () => "M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6.02964 4.16625H5.96918C5.88072 4.17145 5.79375 4.20784 5.72616 4.27544L3.60483 6.39676C3.45839 6.5432 3.45839 6.78064 3.60483 6.92709C3.75128 7.07353 3.98872 7.07353 4.13517 6.92709L5.99957 5.06288L7.86366 6.92709C8.0101 7.07353 8.24754 7.07353 8.39399 6.92709C8.54043 6.78064 8.54043 6.5432 8.39399 6.39676L6.27266 4.27544C6.20507 4.20784 6.1181 4.17145 6.02964 4.16625Z",
        pathWidth: 6,
        pathHeight: 6,
        y: ({ yScale, datum }) => yScale(datum.strategy?.price || datum.close),
    }

    const svgSale = {
        fill: "#EE1818",
        path: () => "M6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM5.97036 7.83375L6.03082 7.83375C6.11928 7.82855 6.20625 7.79216 6.27384 7.72456L8.39517 5.60324C8.54161 5.4568 8.54161 5.21936 8.39517 5.07291C8.24872 4.92647 8.01128 4.92647 7.86484 5.07291L6.00043 6.93712L4.13635 5.07291C3.9899 4.92647 3.75246 4.92647 3.60602 5.07291C3.45957 5.21936 3.45957 5.4568 3.60602 5.60324L5.72734 7.72456C5.79493 7.79216 5.8819 7.82855 5.97036 7.83375Z",
        pathWidth: 6,
        pathHeight: 6,
        y: ({ yScale, datum }) => yScale(datum.strategy?.price || datum.close),
    }

    const svgBuyLoss = {
        fill: "#ffffff",
        path: () => "M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM6.02964 4.16625H5.96918C5.88072 4.17145 5.79375 4.20784 5.72616 4.27544L3.60483 6.39676C3.45839 6.5432 3.45839 6.78064 3.60483 6.92709C3.75128 7.07353 3.98872 7.07353 4.13517 6.92709L5.99957 5.06288L7.86366 6.92709C8.0101 7.07353 8.24754 7.07353 8.39399 6.92709C8.54043 6.78064 8.54043 6.5432 8.39399 6.39676L6.27266 4.27544C6.20507 4.20784 6.1181 4.17145 6.02964 4.16625Z",
        pathWidth: 6,
        pathHeight: 6,
        y: ({ yScale, datum }) => yScale(datum.strategy?.price || datum.close),
    }

    const svgSaleLoss = {
        fill: "#ffffff",
        path: () => "M6 12C2.68629 12 2.34843e-07 9.31371 5.24537e-07 6C8.1423e-07 2.68629 2.68629 -8.1423e-07 6 -5.24537e-07C9.31371 -2.34843e-07 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM5.97036 7.83375L6.03082 7.83375C6.11928 7.82855 6.20625 7.79216 6.27384 7.72456L8.39517 5.60324C8.54161 5.4568 8.54161 5.21936 8.39517 5.07291C8.24872 4.92647 8.01128 4.92647 7.86484 5.07291L6.00043 6.93712L4.13635 5.07291C3.9899 4.92647 3.75246 4.92647 3.60602 5.07291C3.45957 5.21936 3.45957 5.4568 3.60602 5.60324L5.72734 7.72456C5.79493 7.79216 5.8819 7.82855 5.97036 7.83375Z",
        pathWidth: 6,
        pathHeight: 6,
        y: ({ yScale, datum }) => yScale(datum.strategy?.price || datum.close),
    }

    const RateInfo = (props) => {

        const colorStyle = activeHoverTooltipRate.open < activeHoverTooltipRate.close?"#27a69a":"#ef5350";

        const conditionsBuy = activeHoverTooltipRate.strategy?.conditions?.length && activeHoverTooltipRate.strategy?.conditions.filter(c => c.category == "buy") || [];
        const conditionsSale = activeHoverTooltipRate.strategy?.conditions?.length && activeHoverTooltipRate.strategy?.conditions.filter(c => c.category == "sale") || [];

        const getConditionsName = (key) => {
            switch (key) {
                case "buy1":
                    return "Свеча близка к низу";
                case "buy2":
                    return "Пересечение свечой BB";
                case "buy3":
                    return "Свеча рядом с ББ, но угол почти прямой";
                case "buy4":
                    return "Резкий скачок";
                case "buy4F1":
                    return "Флэт";
                case "buy5":
                    return "Пересечение пред. свечами BB";
                case "buyR1":
                    return "Есть расстояние для хода";
                case "buyR2":
                    return "Рядом нет линии тренда";
                case "buyR3":
                    return "Проверка высоты свечи и цвет";
                case "buyR4":
                    return "Исключает микро свечи перед текущей";
                case "buyR5":
                    return "Исключает зеркальные свечи";
                case "buyR6":
                    return "Исключает флэт";

                case "sale1":
                    return "Свеча близка к верху";
                case "sale2":
                    return "Пересечение свечой BB";
                case "sale3":
                    return "Свеча рядом с ББ, но угол почти прямой";
                case "sale4":
                    return "Резкий скачок";
                case "sale4F1":
                    return "Флэт";
                case "sale5":
                    return "Пересечение пред. свечами BB";
                case "saleR1":
                    return "Есть расстояние для хода";
                case "saleR2":
                    return "Рядом нет линии тренда";
                case "saleR3":
                    return "Проверка высоты свечи и цвет";
                case "saleR4":
                    return "Исключает микро свечи перед текущей";
                case "saleR5":
                    return "Исключает зеркальные свечи";
                case "saleR6":
                    return "Исключает флэт";
            }
        };

        return (
            <div className={classes.rateInfoWrapper}>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span><CircleIcon fontSize="small" sx={{ color: colorStyle || "grey"}} style={{marginBottom: -6}} /> open / close</span></div>
                    <div>
                        {activeHoverTooltipRate.open?.toFixed(5)} / {activeHoverTooltipRate.close?.toFixed(5)} ({Math.round((activeHoverTooltipRate.close - activeHoverTooltipRate.open)*100000 )})pips
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>low / high</span></div>
                    <div>
                        {activeHoverTooltipRate.low?.toFixed(5)} / {activeHoverTooltipRate.high?.toFixed(5)} ({Math.round((activeHoverTooltipRate.high - activeHoverTooltipRate.low)*100000 )})pips
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↑rateTop / ↓rateBottom</span></div>
                    <div>
                        {activeHoverTooltipRate.low?.toFixed(5)} / {activeHoverTooltipRate.high?.toFixed(5)} ({Math.round((activeHoverTooltipRate.high - activeHoverTooltipRate.low)*100000 )})pips
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>MACD (val, deg)</span></div>
                    <div>
                        {activeHoverTooltipRate.MACD?.toFixed(5)} (<i>2:</i> {activeHoverTooltipRate.techAnalysis.deg[2].MACD}° /  <i>3:</i> {activeHoverTooltipRate.techAnalysis.deg[3].MACD}°)
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>RSI (val, deg)</span></div>
                    <div>
                        {activeHoverTooltipRate.RSI?.toFixed(1)} (<i>3:</i> {activeHoverTooltipRate.techAnalysis.deg[3].RSI}° /  <i>5:</i> {activeHoverTooltipRate.techAnalysis.deg[5].RSI}° /  <i>7:</i> {activeHoverTooltipRate.techAnalysis.deg[7].RSI}°)
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↑BBUpper / ↓BBLower</span></div>
                    <div>
                        {activeHoverTooltipRate.techAnalysis.BollingerBands.upper.toFixed(5)} / {activeHoverTooltipRate.techAnalysis.BollingerBands.lower?.toFixed(5)} ({activeHoverTooltipRate.techAnalysis.BollingerBands.h}pips)
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↥BBTopDist / ↧BBBottomDist</span></div>
                    <div>
                        {activeHoverTooltipRate.techAnalysis.BollingerBands.topDistance} ({activeHoverTooltipRate.techAnalysis.BollingerBands.topDistancePercent}%) {activeHoverTooltipRate.techAnalysis.BollingerBands.bottomDistance} ({activeHoverTooltipRate.techAnalysis.BollingerBands.bottomDistancePercent}%)
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↑BBUpperDeg</span></div>
                    <div>
                        <i>2:</i> {activeHoverTooltipRate.techAnalysis.deg[2].BBUpper}° /  <i>3:</i> {activeHoverTooltipRate.techAnalysis.deg[3].BBUpper}°
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↓BBLowerDeg</span></div>
                    <div>
                        <i>2:</i> {activeHoverTooltipRate.techAnalysis.deg[2].BBLower}° /  <i>3:</i> {activeHoverTooltipRate.techAnalysis.deg[3].BBLower}°
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>↥resistDist / ↧supportDist</span></div>
                    <div>
                        {activeHoverTooltipRate.resistance?.distance?.toFixed(1)} ({activeHoverTooltipRate.resistance?.distancePercent}%) / {activeHoverTooltipRate.support?.distance?.toFixed(1)} ({activeHoverTooltipRate.support?.distancePercent}%)
                    </div>
                </div>

                <div className={classes.rateInfoOptionsRow}>
                    <div><span>Graph degree angle</span></div>
                    <div>
                        <i>2:</i> {activeHoverTooltipRate.techAnalysis.deg[2].graph}° /  <i>3:</i> {activeHoverTooltipRate.techAnalysis.deg[3].graph}° /  <i>4</i>: {activeHoverTooltipRate.techAnalysis.deg[4].graph}° /  <i>5:</i> {activeHoverTooltipRate.techAnalysis.deg[5].graph}°<br/>
                        <i>6:</i> {activeHoverTooltipRate.techAnalysis.deg[6].graph}° /  <i>7:</i> {activeHoverTooltipRate.techAnalysis.deg[7].graph}° /  <i>8</i>: {activeHoverTooltipRate.techAnalysis.deg[8].graph}° /  <i>9:</i> {activeHoverTooltipRate.techAnalysis.deg[9].graph}°<br/>
                        <i>10:</i> {activeHoverTooltipRate.techAnalysis.deg[10].graph}° /  <i>11:</i> {activeHoverTooltipRate.techAnalysis.deg[11].graph}° /  <i>12</i>: {activeHoverTooltipRate.techAnalysis.deg[12].graph}°
                    </div>
                </div>


                {!!conditionsBuy.length &&
                    <div className={classes.rateInfoOptionsRow}>
                        <div><span><CircleIcon fontSize="small" sx={{ color: "#0f80fc"}} style={{marginBottom: -6}} /> <span style={{...(activeHoverTooltipRate.strategy.buy?{color: "#33ff33", fontWeight: 600 }:{})}}>conditions BUY-PASS</span></span></div>
                        <div>
                            {conditionsBuy.filter(c => !c.required).map(c => (<p key={c.key} className={(c.status?"active":null)}>{c.key} - {getConditionsName(c.key)}</p>))}
                            <p style={{color: "#ff8400"}}>required :</p>
                            {conditionsBuy.filter(c => c.required).map(c => (<p key={c.key} className={(c.status?"active":null)}>{c.key} - {getConditionsName(c.key)}</p>))}
                        </div>
                    </div>
                }

                {!!conditionsSale.length &&
                    <div className={classes.rateInfoOptionsRow}>
                        <div><span><CircleIcon fontSize="small" sx={{ color: "red"}} style={{marginBottom: -6}} /> <span style={{...(activeHoverTooltipRate.strategy.sale?{color: "#33ff33", fontWeight: 600}:{})}}>conditions SALE-PASS</span></span></div>
                        <div>
                            {conditionsSale.filter(c => !c.required).map(c => (<p key={c.key} className={(c.status?"active":null)}>{c.key} - {getConditionsName(c.key)}</p>))}
                            <p style={{color: "#ff8400"}}>required :</p>
                            {conditionsSale.filter(c => c.required).map(c => (<p key={c.key} className={(c.status?"active":null)}>{c.key} - {getConditionsName(c.key)}</p>))}
                        </div>
                    </div>
                }


            </div>
        )
    }

    return (
        <div className={classes.chartWrapper}>

            <div className={classes.chart} style={{height, width: width-500}}>

            <ChartCanvas
                height={height}
                ratio={ratio}
                width={width-500}
                margin={margin}
                seriesName={"EURUSD"}
                data={chartData.data}
                xScale={chartData.xScale}
                xAccessor={chartData.xAccessor}
                displayXAccessor={chartData.displayXAccessor}
                xExtents={chartData.xExtents}
                zoomAnchor={lastVisibleItemBasedZoomAnchor}

            >

                {/* График объемов */}
                <Chart
                    id={1}
                    height={gridHeight/20}
                    origin={(w, h) => {
                        //расположение по X и Y
                        return [0, gridHeight/2 - gridHeight/20]
                    }}
                    yExtents={(d) => d.volume}
                >
                    <BarSeries
                        fillStyle={(d) => d.close > d.open ? "rgba(38, 166, 154, 0.3)" : "rgba(239, 83, 80, 0.3)"}
                        yAccessor={(d) => d.volume}
                    />
                </Chart>

                {/* Ценовой график */}
                <Chart
                    id={2}
                    //вычисляет диапазон значений по оси Y (установки масштаба по оси Y с помощью библиотеки d3)
                    yExtentsCalculator={yExtentsCalculator}
                    height={gridHeight/2}
                >

                    {/*
                    <Label
                        x={width / 2}
                        y={gridHeight/2-60}
                        fontSize={20}
                        fontWeight={"1"}
                        text={`Forex-Robot`}
                    />
                    */}

                    {/* точки входа стратегия */}
                    <Annotate
                        with={SvgPathAnnotation}
                        usingProps={svgBuy}
                        when={(data) => data.strategy?.buy && !data.strategy?.loss}
                    />

                    <Annotate
                        with={SvgPathAnnotation}
                        usingProps={svgSale}
                        when={(data) => data.strategy?.sale && !data.strategy?.loss}
                    />

                    {/* Убыточные сделки */}

                    <Annotate
                        with={SvgPathAnnotation}
                        usingProps={svgBuyLoss}
                        when={(data) => data.strategy?.buy && data.strategy?.loss}
                    />

                    <Annotate
                        with={SvgPathAnnotation}
                        usingProps={svgSaleLoss}
                        when={(data) => data.strategy?.sale && data.strategy?.loss}
                    />

                    {/* ПИКИ */}
                    <Annotate
                        with={LabelAnnotation}
                        usingProps={{
                            fontSize: 12,
                            fill: 'green',
                            text: "пик",
                            //y задается в пунктах
                            y: ({ yScale, datum }) => yScale(datum.high+0.0001)
                        }}
                        when={(data) => data.techAnalysis?.peak?.type == "maxPeak"}
                    />

                    <Annotate
                        with={LabelAnnotation}
                        usingProps={{
                            fontSize: 12,
                            fill: 'red',
                            text: "пик",
                            //y задается в пунктах
                            y: ({ yScale, datum }) => yScale(datum.low-0.0002)
                        }}
                        when={(data) => data.techAnalysis?.peak?.type == "minPeak"}
                    />

                    {/* Собственно сам график цены */}
                    <CandlestickSeries/>

                    {/* EMA */}
                    <LineSeries yAccessor={(d) => d.techAnalysis.EMA200} strokeStyle="white"/>

                    {/* линия поддержки */}

                    {tlSupportH.map((tls, index) => (
                        <LineSeries
                            key={index}
                            yAccessor={(d) => d.techAnalysis?.levels?.support[index]?.height || null}
                            strokeStyle="red"
                            strokeType="point"
                            defined={(d) => {
                                if(tlSupportH[index] != d){
                                    tlSupportH[index] = d;
                                    return false;
                                }
                                return d;
                            }}
                        />
                    ))}

                    {tlResistanceH.map((tls, index) => (
                        <LineSeries
                            key={index}
                            yAccessor={(d) => d.techAnalysis?.levels?.resistance[index]?.height || null}
                            strokeStyle="green"
                            strokeType="point"
                            defined={(d) => {
                                if(tlResistanceH[index] != d){
                                    tlResistanceH[index] = d;
                                    return false;
                                }
                                return d;
                            }}
                        />
                    ))}

                    {/*
                        <TrendLine
                            lines={[
                                { start: [chartData.data[chartData.data.length-10].date, chartData.data[chartData.data.length-10].high], end: [chartData.data[chartData.data.length-20].date, chartData.data[chartData.data.length-20].low] },
                                // Здесь можно добавить еще линии по аналогии с вышеуказанной
                            ]}
                        />
                    */}

                    {/* Полоса текущей цены закрытия */}
                    <EdgeIndicator
                        itemType="last"
                        rectWidth={margin.right}
                        fill={openCloseColor}
                        lineStroke={openCloseColor}
                        displayFormat={priceDisplayFormat}
                        yAccessor={(d) => d.close}
                    />

                    {/* Верхняя подсказка с текущими даннымы активного бара */}
                    <OHLCTooltip
                        origin={[8, 16]}
                        textFill={openCloseColor}
                        className="react-no-select"
                    />

                    {/* Активная подсказка */}
                    <HoverTooltip
                        yAccessor={d => d}
                        fontSize={12}
                        tooltip={
                            {
                                content: ({currentItem, xAccessor}) => {

                                    setActiveHoverTooltipRate(currentItem);

                                    return {
                                        x: moment(xAccessor(currentItem)).format("DD.MM.YY HH:mm"),
                                        y: [],
                                    };

                                }
                            }
                        }
                    />

                    {/* Шкала времени внизу графика */}
                    <XAxis {...axisStyles} showGridLines />
                    {/* Шкала цены справа графика */}
                    <YAxis {...axisStyles} showGridLines />

                    {/* Координаты x и y на шкалах*/}
                    <MouseCoordinateX displayFormat={timeDisplayFormat}{...coordinateStyles}/>
                    <MouseCoordinateY rectWidth={margin.right} displayFormat={priceDisplayFormat}{...coordinateStyles}/>

                </Chart>


                {/* НИЖНИЙ ГРАФИК */}
                {/* выводим Chart только если в нем есть данные для отображения */}
                {!!(
                    bottomGraph && ["price", "linearRegressionCandlesFromuGurvuTV"].indexOf(bottomGraph) > -1 ||
                    bottomGraphIndicator && ["BollingerBands", "EMA20", "EMA50", "EMA100", "EMA200"].indexOf(bottomGraphIndicator) > -1
                ) &&
                    <Chart
                        id={200}
                        //вычисляет диапазон значений по оси Y (установки масштаба по оси Y с помощью библиотеки d3)
                        yExtentsCalculator={yExtentsCalculator}
                        height={gridHeight/2}
                        origin={(w, h) => {
                            //расположение по X и Y
                            return [0, gridHeight/2]
                        }}
                    >

                        {/* график цены */}
                        {!!(bottomGraph && bottomGraph == "price") &&
                        <CandlestickSeries/>
                        }

                        {/* Bollinger */}
                        {!!(bottomGraphIndicator && bottomGraphIndicator == "BollingerBands") &&
                            <BollingerSeries
                                yAccessor={d => ({
                                    //top: d.techAnalysis.BollingerBands.upper - bollingerBandsIndentation,
                                    //bottom: d.techAnalysis.BollingerBands.lower + bollingerBandsIndentation,
                                    top: d.BollingerBandsUpper,
                                    bottom: d.BollingerBandsLower,
                                    middle: d.techAnalysis.BollingerBands.middle,

                                })}
                            />
                        }


                        {/* Линии линейной регрессии */}
                        {!!(bottomGraph && bottomGraph == "linearRegressionCandlesFromuGurvuTV") &&
                            <CandlestickSeries
                                yAccessor={d => ({
                                    open: d.techAnalysis.linearRegressionCandlesFromuGurvuTV.open,
                                    high: d.techAnalysis.linearRegressionCandlesFromuGurvuTV.high,
                                    low: d.techAnalysis.linearRegressionCandlesFromuGurvuTV.low,
                                    close: d.techAnalysis.linearRegressionCandlesFromuGurvuTV.close,
                                })}
                            />
                        }

                        {/* EMA20 */}
                        {!!(bottomGraphIndicator && bottomGraphIndicator == "EMA20") &&
                            <LineSeries yAccessor={(d) => d.techAnalysis.EMA20} strokeStyle="white"/>
                        }

                        {/* EMA50 */}
                        {!!(bottomGraphIndicator && bottomGraphIndicator == "EMA50") &&
                            <LineSeries yAccessor={(d) => d.techAnalysis.EMA50} strokeStyle="white"/>
                        }

                        {/* EMA100 */}
                        {!!(bottomGraphIndicator && bottomGraphIndicator == "EMA100") &&
                            <LineSeries yAccessor={(d) => d.techAnalysis.EMA100} strokeStyle="white"/>
                        }

                        {/* EMA200 */}
                        {!!(bottomGraphIndicator && bottomGraphIndicator == "EMA200") &&
                            <LineSeries yAccessor={(d) => d.techAnalysis.EMA200} strokeStyle="white"/>
                        }

                        {/* Шкала времени внизу графика */}
                        <XAxis {...axisStyles} showGridLines />
                        {/* Шкала цены справа графика */}
                        <YAxis {...axisStyles} showGridLines />

                        {/* Координаты x и y на шкалах*/}
                        <MouseCoordinateX displayFormat={timeDisplayFormat}{...coordinateStyles}/>
                        <MouseCoordinateY rectWidth={margin.right} displayFormat={priceDisplayFormat}{...coordinateStyles}/>

                    </Chart>
                }


                {/* yExtentsCalculatorRSINorm */}
                {!!(bottomGraphIndicator && bottomGraphIndicator == "RSINorm") &&
                    <Chart
                        id={200}
                        //вычисляет диапазон значений по оси Y (установки масштаба по оси Y с помощью библиотеки d3)
                        yExtentsCalculator={yExtentsCalculatorRSINorm}
                        height={gridHeight / 2}
                        origin={(w, h) => {
                            //расположение по X и Y
                            return [0, gridHeight / 2]
                        }}
                    >

                        <LineSeries yAccessor={(d) => d.normRSI} strokeStyle="red"/>

                        {/* Шкала времени внизу графика */}
                        <XAxis {...axisStyles} showGridLines/>
                        {/* Шкала цены справа графика */}
                        <YAxis {...axisStyles} showGridLines/>

                        {/* Координаты x и y на шкалах*/}
                        <MouseCoordinateX displayFormat={timeDisplayFormat}{...coordinateStyles}/>
                        <MouseCoordinateY rectWidth={margin.right} displayFormat={priceDisplayFormat}{...coordinateStyles}/>

                    </Chart>
                }


                {/* RSI */}
                {!!(bottomGraphIndicator && bottomGraphIndicator == "RSI") &&
                    <Chart
                        id={8}
                        yExtentsCalculator={yExtentsCalculatoRSI}
                        height={gridHeight/2}
                        origin={(w, h) => {
                            //расположение по X и Y
                            return [0, gridHeight/2]
                        }}
                    >
                        <YAxis {...axisStyles}/>

                        <RSISeries yAccessor={d => d.techAnalysis.RSI} />

                        <MouseCoordinateY rectWidth={margin.right} displayFormat={priceDisplayFormat}{...coordinateStyles}/>

                    </Chart>
                }


                {/* Дополнительные линии */}
                {/*
                    <Chart
                        id={33}
                        xExtents={chartData.xExtents}
                        yExtents={(d) => d.volume}
                    >
                        <line x1={100} y1={100} x2={300} y2={300} stroke="red" strokeWidth={2} />
                        <line x1={200} y1={50} x2={400} y2={250} stroke="blue" strokeWidth={2} />

                    </Chart>
                */}


                {/* MACD*/}
                {!!(bottomGraphIndicator && bottomGraphIndicator == "MACD") &&
                    <Chart
                        id={5}
                        //yExtents={d => d.techAnalysis.MACD.MACD}
                        yExtentsCalculator={yExtentsCalculatorMACD}
                        height={gridHeight/2}
                        origin={(w, h) => {
                            //расположение по X и Y
                            return [0, gridHeight/2]
                        }}
                    >
                        <YAxis {...axisStyles}/>
                        <MACDSeries yAccessor={d => ({
                            divergence: d.techAnalysis.MACD.histogram,
                            signal: d.techAnalysis.MACD.signal,
                            macd: d.techAnalysis.MACD.MACD,
                        })}
                        />

                        <MouseCoordinateY rectWidth={margin.right} displayFormat={priceDisplayFormat}{...coordinateStyles}/>

                    </Chart>
                }

                <CrossHairCursor {...crossHairStyles} />

            </ChartCanvas>
        </div>

            {activeHoverTooltipRate && <RateInfo/>}

        </div>

    );
};

FinancialChart.propTypes = {
    //dateTimeFormat: PropTypes.string,
    height: PropTypes.number,
    margin: PropTypes.object,
    priceDisplayFormat: PropTypes.func,
    ratio: PropTypes.number,
    width: PropTypes.number
};

FinancialChart.defaultProps = {
    //dateTimeFormat: "%d %b '%y \xa0 %H:%M",
    height: 0,
    margin: { left: 0, right: 70, top: 0, bottom: 24 },
    priceDisplayFormat: format(".5f"),
    ratio: 0,
    width: 0
};


/*
export const PriceChart = withSize({ style: { minHeight: 600 } })(
    withDeviceRatio()(FinancialChart)
);
 */

const PriceChartWithMemo = React.memo((props) => {

    const [windowDimensions, setWindowDimensions] = useState({});

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        setWindowDimensions({width: width, height});
    };

    window.addEventListener('resize', getWindowDimensions);

    useEffect(() => {
        getWindowDimensions();
    },[]);

    if(!(windowDimensions.width && windowDimensions.height)){
        return <div/>
    }

    const margin = {top: 0, left: 0, bottom: 0, right: 60};
    const gridHeight = windowDimensions.height - margin.top - margin.bottom;

    const timeDisplayFormat = timeFormat("%d %b '%y \xa0 %H:%M");

    console.log("re-render PriceChartWithMemo");

    return (
        <FinancialChart
            {...props}
            width={windowDimensions.width}
            height={windowDimensions.height-42}
            ratio={1}
            margin={margin}
            gridHeight={gridHeight}

            timeDisplayFormat={timeDisplayFormat}
        />
    );
},(b, a) => {
    //if(b.selectedData != a.selectedData) return false;
    return true;
});

export default PriceChartWithMemo;