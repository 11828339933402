import React, { createContext, useState } from "react";
import moment from "moment";

export const GlobalStateContext = createContext();

const GlobalStateProvider = ({children}) => {
    const [tFrame, setTFrame] = useState(5);
    const [bottomGraph, setBottomGraph] = useState("price");
    const [bottomGraphIndicator, setBottomGraphIndicator] = useState("");
    const [backdrop, setBackdrop] = useState(false);
    const [activeStrategy, setActiveStrategy] = useState({
        name: "bb5tf",
        labelName: "bb5tf",
        startDate: moment().subtract(2, 'week').startOf("day"),
        endDate: moment().startOf("day"),
        status: "not-active",
        rates: [],
    });
    //тестирование участка активной стратегии (без сброса графика)
    const [testStrategyRange, setTestStrategyRange] = useState({
        startDate: moment().startOf("day"),
        endDate: moment().startOf("hour")
    });
    const [toast, setToast] = useState({open: false, severity: "error", "text": ""});

    return (
        <GlobalStateContext.Provider
            value={{
                tFrame, setTFrame,
                bottomGraph, setBottomGraph,
                bottomGraphIndicator, setBottomGraphIndicator,
                backdrop, setBackdrop,
                activeStrategy, setActiveStrategy,
                testStrategyRange, setTestStrategyRange,
                toast, setToast,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export default GlobalStateProvider;
