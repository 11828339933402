import React, {useContext} from "react";
import Router from "./router/Router";
import Layout from "./components/Layout";
import {UserContext} from "./providers/UserProvider";
import useGStyles from "./assets/global-styles";
import CircularProgress from '@mui/material/CircularProgress';

const App = () => {

    const gClasses = useGStyles();

    //если попытка загрузить данные пользователя еще в процессе
    const {loggingIn} = useContext(UserContext);
    //отображаем загрузку
    if(loggingIn){
        return (
            <div className={gClasses.loadingWrapper}>
                <CircularProgress/>
            </div>
        );
    };

    return (
        <Layout>
          <Router />
        </Layout>
    );
};

export default App;
