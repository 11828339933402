import React from 'react';
import {makeStyles} from "@mui/styles";

const onePx = 0.0625;
export default makeStyles((theme) => ({

    regBtnsWrapper: {
        padding: 15,
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        zIndex: 2,
    },

    regHeader: {
        fontSize: 20*onePx+"rem",
        textAlign: "center",
        marginBottom: 20,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    sendCodeInput: {
        width: "100%",
        maxWidth: 300,
        marginBottom: "16px!important",
        "& .MuiIconButton-root": {
            background: "#4CAF50",
            borderRadius: 8,
            color: "#fff"
        },
        "& .Mui-disabled": {
            background: "rgba(49, 67, 83, 0.07)"
        },
        "& .MuiInputBase-input": {
            fontSize: 20*onePx+"rem",
        }
    },
    enterCodeItem: {
        display: "flex",
        width: "100%",
        position: "relative",
        justifyContent: "center",
        "& .MuiInput-root": {
            width: 200,
        },
        "& .MuiInput-input": {
            "width": "100%",
            "border": "none",
            "background": "none",
            "fontSize": "50px",
            "padding": "0 22px",
            "textAlign": "center",
            "letterSpacing": "0.3rem",
            "outline": "none",
        },
        "& .Mui-disabled": {
            color: "rgba(16, 32, 47, 0.35)"
        }
    },
    inputHelperText: {
        position: "absolute",
        top: 75,
        width: "100%",
        textAlign: "center",
    },

    formInput: {
        width: "100%",
        marginBottom: "16px!important",
    },

    authWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        width: "100%",
        flex: 1,
        height: "100%",
        maxWidth: 720,
        margin: "auto",
        padding: 20,
    },

    regWrapper: {
        //display: "flex",
        //alignItems: "center",
        //justifyContent: "center",
        //overflow: "auto",
        width: "100%",
        //flex: 1,
        //height: "100%",
        maxWidth: 720,
        margin: "auto",
        padding: 20,
    },

}));