import instance from "../api/axios.api";

const DataService = {

    async getRates(options) {
        const { data } = await instance.post("/data/get-rates", {...options});
        return data;
    },

};
export default DataService;
