import React, {useState, useContext} from 'react'
import { GlobalStateContext } from "../providers/GlobalStateProvider.jsx";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import {FormControlLabel} from "@mui/material";
import useStyles from "./styles";
import useGStyles from "../assets/global-styles";
import CustomSnackbar from "./custom-snackbar/CustomSnackbar";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UIService from "../services/ui.service.js";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import CalculateIcon from '@mui/icons-material/Calculate';

const strategies = [
	{
		name: "bb",
		labelName: "bb"
	},

	{
		name: "bb5tf",
		labelName: "bb5tf"
	},
	{
		name: "bb30tf",
		labelName: "bb30tf"
	},
	{
		name: "bb60tf",
		labelName: "bb60tf"
	},
	{
		name: "rsi",
		labelName: "rsi"
	},
	{
		name: "tl",
		labelName: "tl"
	}
	];



const predictToPipsLite = (value) => {

	if(value == 0) return "< -18";
	if(value == 1) return "> +18";

	try{
		value = value.toFixed(1)
	}catch (e) {
		return "???";
	}

	switch (value) {
		case "0":
			return "< -18";
		case "0.1":
			return "-16±2";
		case "0.2":
			return "-11±3";
		case "0.3":
			return "-6.5±1.5";
		case "0.4":
			return "-3.5±1.5";
		case "0.5":
			return "0±2";
		case "0.6":
			return "+3.5±1.5";
		case "0.7":
			return "+6.5±1.5";
		case "0.8":
			return "+11±3";
		case "0.9":
			return "+16±2";
		case "1":
			return "> +18";
	}
}

const calcStat = (data) => {

	let trend = 0;
	let exact = 0;
	let error = 0;
	let minorMistake = 0;

	for (let i = 0; i < data.length; i++) {



		let {trueFuture, predict} = data[i];

		console.log("trueFuture="+trueFuture+", predict="+predict+"("+predict.toFixed(1)+")");

		//округление до 2 знака
		predict = +predict.toFixed(1);

		//калькуляция
		if(trueFuture < 0.5 && predict < 0.5){
			//падение
			//угадан тренд или значение
			if(trueFuture == predict){
				exact++
			}else{
				trend++;
			}
		}else if(trueFuture > 0.5 && predict > 0.5){
			//рост
			//угадан тренд или значение
			if(trueFuture == predict){
				exact++
			}else{
				trend++;
			}
		}else if(trueFuture == 0.5 && predict == 0.5){
			//без изменений
			//угадан тренд и значение
			exact++
		}else if(predict == 0.5){
			//если предсказания нет, а движение есть
			if(trueFuture == 0.6 || trueFuture == 0.4){
				//мелкая ошибка
				minorMistake++
			}else{
				//крупная ошибка
				error++;
			}
		}else if(trueFuture == 0.5){
			//если предсказания есть, но движения нет
			if(predict == 0.6 || predict == 0.4){
				//мелкая ошибка
				minorMistake++
			}else{
				//крупная ошибка
				error++;
			}
		}else{
			//не угадано значение
			//крупная ошибка
			error++;
		}

	}

	console.log(data);
	console.log("Калькуляция ошибок завершена!");
	return {trend, exact, error, minorMistake};

}


const Layout = ({children}) => {

	const classes = useStyles();
	const gClasses = useGStyles();

	//глобальный state
	const {
		tFrame, setTFrame,
		bottomGraph, setBottomGraph,
		bottomGraphIndicator, setBottomGraphIndicator,
		backdrop, setBackdrop,
		activeStrategy, setActiveStrategy,
		testStrategyRange, setTestStrategyRange,
		toast, setToast,
	} = useContext(GlobalStateContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const [dialog, setDialog] = useState({
		show: false,
		title: null,
		description: null,
		onConfirm: null,
	});

	const [datetime, setDatetime] = useState(moment());
	const [showModalChangeDate, setShowModalChangeDate] = useState(false);
	const [showModalTestStrategy, setShowModalTestStrategy] = useState(false);
	const [rangePredict, setRangePredict] = useState(false);
	const [datetimeRange, setDatetimeRange] = useState(moment());

	const onClickMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const onCloseMenu = () => {
		setAnchorEl(null);
	};

	const onStartTrainAI = () => {
		onCloseDialog();
        setAnchorEl(null);

		UIService.runTrainAI().then((res, err) => {

			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			if(error) {
				return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});
			}

			setToast({open: true, severity: "success", text: "Обучение нейросети успешно запущено! В работу отправлено "+res.count+" записей."});
			console.log("Запускаю обучение нейросети");

		});

	}

	const onTrainAI = () => {
		setAnchorEl(null);

		setDialog({
			show: true,
			title: "Вы уверен, что хотите запустить обучение нейросети?",
			description: "Операция может занять длительное время (до нескольких часов).",
			onConfirm: () => onStartTrainAI(),
		})
	};

	const onGenerateDatasetToAI = () => {

		setAnchorEl(null);
		setBackdrop(true);

		UIService.generateDatasetToAi({tFrame}).then((res, err) => {

			setBackdrop(false);
			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			if(error) {
				return setToast({open: true, severity: "error", closeTime: 15000, text: error, widthAuto: true});
			}

			setToast({open: true, severity: "success", text: "Dataset успешно сформирован, кол-во элементов: "+res.count+", название файла: "+res.name});

		});
	};

	const onChangeTFrame = (event) => {
		setTFrame(event.target.value);
	};

	const onChangeBottomGraph = (event) => {
		setBottomGraph(event.target.value);
	};

	const onChangeBottomGraphIndicator = (event) => {
		setBottomGraphIndicator(event.target.value);
	};

	const onChangePredictDate = (date) => {
		setDatetime(moment(date).seconds(0));
		console.log("onChangePredictDate="+moment(date).format("DD.MM.YY HH:mm:ss"));
	};

	const onChangeRangePredictDate = (date) => {
		setDatetimeRange(moment(date).seconds(0));
		console.log("onChangePredictDate="+moment(date).format("DD.MM.YY HH:mm:ss"));
	};

	const onRunPredictAI = () => {

        setAnchorEl(null);
		setShowModalChangeDate(false);

		console.log("onRunPredictAI date="+moment(datetime).format("DD.MM.YY HH:mm:ss"));

		UIService.runPredictAI({date: moment(datetime).toDate()}).then((res, err) => {

			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			if(error) {
				return setToast({open: true, severity: "error", closeTime: 10000, text: error, widthAuto: true});
			}

			setToast({open: true, severity: "success", closeTime: 15000, text: (
					<div>
						<p>Для даты {moment(res.date).format("DD.MM.YY HH:mm:ss")}</p>
						<p style={{fontWeight: 600, marginBottom: 5}}>Реальные изменения графика:</p>
						<p style={{marginTop: 0}}>{res.trueFutureStr}</p>
						<p style={{fontWeight: 600, marginBottom: 5}}>Предсказание модели:</p>
						<p style={{marginTop: 0}}>{res.predictStr}</p>
					</div>
				)});

		});

	}

	const onRunRangePredictAI = async () => {

		if(!rangePredict){
			return setToast({open: true, severity: "error", closeTime: 10000, text: "Вы не выбрали прогноз за период!", widthAuto: true});
		}

		if(!(datetime && datetimeRange)){
			return setToast({open: true, severity: "error", closeTime: 10000, text: "Необходимо указать 2 даты для прогноза периода!", widthAuto: true});
		}

		if(!moment(datetime).isBefore(datetimeRange)){
			return setToast({open: true, severity: "error", closeTime: 10000, text: "Дата-1 должна быть меньше Дата-2!", widthAuto: true});
		}

		const steps = Math.round(moment(datetimeRange).second(0).add(1, "minutes").diff(moment(datetime).second(0), "minutes")/5);

		let dataStat = [];

		console.log("дата | реал.зн. | прогноз");
		for (let i = 0; i < steps; i++) {

			let date = moment(datetime).add(i*5, "minutes").toDate();
			//console.log("onRunRangePredictAI date="+moment(date).format("DD.MM.YY HH:mm:ss"));

			const result = await UIService.runPredictAI({date: moment(date).toDate()});

			if(result.status){
				dataStat.push({trueFuture: result.trueFuture, predict: result.predict});
			}else{
				console.log("Ошибка получения данных")
			}


			console.log(moment(date).format("DD.MM.YY HH:mm:ss")+" | "+predictToPipsLite(result.trueFuture)+" | "+ predictToPipsLite(result.predict));

		}

		const stat = calcStat(dataStat);
		console.log("Всего сделано прогнозов: "+dataStat.length);
		console.log("Точные предсказания: "+stat.exact+"("+Math.round(stat.exact/dataStat.length*100)+"%)");
		console.log("Угадан тренд: "+stat.trend+"("+Math.round(stat.trend/dataStat.length*100)+"%)");
		console.log("Мелкие ошибки у нуля: "+stat.minorMistake+"("+Math.round(stat.minorMistake/dataStat.length*100)+"%)");
		console.log("Ошибки: "+stat.error+"("+Math.round(stat.error/dataStat.length*100)+"%)");
	}

	const onGetPredictAI = () => {
		setShowModalChangeDate(true);
	};

	const onCloseDialog = () => {
		setDialog({show: false, title: null, description: null, onConfirm: null})
	};

	const onCloseModalChangeDate = () => {
		setShowModalChangeDate(false);
	};

	const onCloseModalTestStrategy = () => {
		setShowModalTestStrategy(false);
	};

	const onChangeTestStrategyStartDate = (date) => {
		setActiveStrategy({...activeStrategy, startDate: moment(date).seconds(0)});
		//console.log("onChangeTestStrategyStartDate="+moment(date).format("DD.MM.YY HH:mm:ss"));
	};

	const onChangeTestStrategyEndDate = (date) => {
		setActiveStrategy({...activeStrategy, endDate: moment(date).seconds(0)});
		//console.log("onChangeTestStrategyEndDate="+moment(date).format("DD.MM.YY HH:mm:ss"));
	};

	const onTestStrategy = () => {
		setAnchorEl(null);
		setShowModalTestStrategy(true);
	}

	const onTestStrategyRange = () => {

		UIService.testStrategy({tFrame, name: activeStrategy.name, startDate: testStrategyRange.startDate, endDate: testStrategyRange.endDate, logging: true}).then((res, err) => {

			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			if(error) {
				return setToast({open: true, severity: "error", closeTime: 15000, text: error, widthAuto: true});
			}

			console.log("Расчет участка стратегии, rates:");
			console.log(res.rates);

			setToast({open: true, severity: "success", text: "Участок стретгии успешно рассчитан!"});
		});
	}

	const onChangeTestStrategyRangeStartDate = (date) => {
		setTestStrategyRange({...testStrategyRange, startDate: moment(date).seconds(0)});
	};

	const onChangeTestStrategyRangeEndDate = (date) => {
		setTestStrategyRange({...testStrategyRange, endDate: moment(date).seconds(0)});
	};

	const onChangeTestStrategyName = (event) => {
		setActiveStrategy({...activeStrategy, name: event.target.value, labelName: strategies.find(st => st.name == event.target.value)?.labelName});
	}

	const onStartTestStrategy = () => {

		const tmpStrategy = {...activeStrategy, status: "loading", rates: []};
		setActiveStrategy(tmpStrategy);

		setShowModalTestStrategy(false);

		setBackdrop(true);

		UIService.testStrategy({tFrame, name: activeStrategy.name, startDate: activeStrategy.startDate, endDate: activeStrategy.endDate}).then((res, err) => {

			setBackdrop(false);
			const error = (err || !(res && res.status)) && (res?.error || "Неизвестная ошибка!");
			if(error) {
				return setToast({open: true, severity: "error", closeTime: 15000, text: error, widthAuto: true});
			}

			setActiveStrategy({...tmpStrategy, status: "active", rates: res.rates, testId: res.testId});

			setToast({open: true, severity: "success", text: "Стратегия "+res.testId+" успешно протестирована!"});

		});
	}

	const onCloseStrategy = () => {
		setActiveStrategy({...activeStrategy, status: "not-active", rates: []})
	}


	return (
		<>
			<AppBar position="static">
				<Toolbar className={classes.toolbar}>
					<IconButton
						size="large"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={onClickMenu}
						color="inherit"
						style={{marginRight: 10}}
					>
						<MenuIcon />
					</IconButton>

					<Menu
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={onCloseMenu}
					>
						<MenuItem onClick={onGenerateDatasetToAI}>Сформировать dataset в csv</MenuItem>
						<MenuItem onClick={onTrainAI}>Запустить обучение AI</MenuItem>
						<MenuItem onClick={onGetPredictAI}>Получить прогноз AI</MenuItem>
						<MenuItem onClick={onTestStrategy}>Тестировать стратегию</MenuItem>
					</Menu>

					<TextField
						style={{width: 100, marginRight: 10}}
						variant="standard"
						size="small"
						select
						value={tFrame}
						onChange={onChangeTFrame}
					>
						<MenuItem value={5}>5 минут</MenuItem>
						<MenuItem value={15}>15 минут</MenuItem>
						<MenuItem value={30}>30 минут</MenuItem>
						<MenuItem value={60}>60 минут</MenuItem>
					</TextField>

					<TextField
						style={{width: 200, marginRight: 10}}
						variant="standard"
						size="small"
						select
						id="bottom_graph_indicator"
						value={bottomGraph}
						onChange={onChangeBottomGraph}
					>
						<MenuItem value=""><em>Без графика</em></MenuItem>
						<MenuItem value="price">Ценовой график</MenuItem>
						<MenuItem value="linearRegressionCandlesFromuGurvuTV">Линейная регрессия</MenuItem>
					</TextField>

					<TextField
						style={{width: 150, marginRight: 10}}
						variant="standard"
						size="small"
						select
						id="bottom_graph"
						value={bottomGraphIndicator}
						onChange={onChangeBottomGraphIndicator}
					>
						<MenuItem value={""}><em>Без индикатора</em></MenuItem>
						<MenuItem value="MACD">MACD</MenuItem>
						<MenuItem value="BollingerBands">Боллинджер</MenuItem>
						<MenuItem value="RSI">RSI</MenuItem>
						<MenuItem value="RSINorm">RSI - Norm</MenuItem>
						<MenuItem value="EMA20">EMA20</MenuItem>
						<MenuItem value="EMA50">EMA50</MenuItem>
						<MenuItem value="EMA100">EMA100</MenuItem>
						<MenuItem value="EMA200">EMA200</MenuItem>
					</TextField>

					{!!(activeStrategy.status && activeStrategy.status == "active") &&
						<div className={classes.toolbarStrategyName}>
							<Chip size="small" label={activeStrategy.labelName} onDelete={onCloseStrategy} />
						</div>
					}

					{!!(activeStrategy.status && activeStrategy.status == "active") &&
						<div className={classes.toolbarTestStrategyRangeWrapper}>

							<LocalizationProvider dateAdapter={AdapterMoment}>
								<DateTimePicker
									size="small"
									format="DD.MM.YYYY HH:mm:ss"
									ampm={false}
									//label="Укажите дату старта тестирования стретегии"
									value={testStrategyRange.startDate}
									disableFuture={true}
									onChange={(newValue) => onChangeTestStrategyRangeStartDate(newValue)}
								/>

								<DateTimePicker
									size="small"
									format="DD.MM.YYYY HH:mm:ss"
									ampm={false}
									//label="Укажите дату финиша тестирования стретегии"
									value={testStrategyRange.endDate}
									disableFuture={true}
									onChange={(newValue) => onChangeTestStrategyRangeEndDate(newValue)}
								/>

							</LocalizationProvider>

							<IconButton onClick={onTestStrategyRange}>
								<CalculateIcon />
							</IconButton>

						</div>
					}

				</Toolbar>

			</AppBar>
			<div className={classes.mainWrapper} >
				{children}
			</div>


			{dialog.show &&
				<Dialog
					open={dialog.show}
					onClose={onCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{dialog.title}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{dialog.description}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={onCloseDialog}>Отменить</Button>
						<Button onClick={dialog.onConfirm} autoFocus>Подтвердить</Button>
					</DialogActions>
				</Dialog>
			}

			<Modal
				className={classes.modal}
				open={showModalChangeDate}
				onClose={onCloseModalChangeDate}
			>
				<div className={classes.modalWrapper}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateTimePicker
							ampm={false}
							label="Укажите дату и время для прогноза"
							value={datetime}
							disableFuture={true}
							onChange={(newValue) => onChangePredictDate(newValue)}
						/>
					</LocalizationProvider>



					<FormControlLabel
						style={{marginRight: 0, marginLeft: 0, marginTop: 20, marginBottom: 10}}
						control={
							<Checkbox
								checked={rangePredict}
								onChange={(event) => setRangePredict(event.target.checked)}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						}
						label="Серия прогнозов"
						color="primary"
					/>

					{rangePredict &&
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DateTimePicker
								ampm={false}
								label="Укажите дату и время для прогноза"
								value={datetimeRange}
								disableFuture={true}
								onChange={(newValue) => onChangeRangePredictDate(newValue)}
							/>
						</LocalizationProvider>
					}

					<div className={classes.modalBtnsWrapper}>
						<Button onClick={onCloseModalChangeDate}>Отмена</Button>
						{rangePredict?
							<Button onClick={onRunRangePredictAI}>Сделать серию прогнозов</Button>
							:
							<Button onClick={onRunPredictAI}>Сделать одиночый прогноз</Button>
						}

					</div>

				</div>
			</Modal>

			<Modal
				className={classes.modal}
				open={showModalTestStrategy}
				onClose={onCloseModalTestStrategy}
			>
				<div className={classes.modalWrapper}>

					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateTimePicker
							format="DD.MM.YYYY HH:mm:ss"
							className={gClasses.mb20}
							ampm={false}
							label="Укажите дату старта тестирования стретегии"
							value={activeStrategy.startDate}
							disableFuture={true}
							onChange={(newValue) => onChangeTestStrategyStartDate(newValue)}
						/>

						<DateTimePicker
							format="DD.MM.YYYY HH:mm:ss"
							className={gClasses.mb20}
							ampm={false}
							label="Укажите дату финиша тестирования стретегии"
							value={activeStrategy.endDate}
							disableFuture={true}
							onChange={(newValue) => onChangeTestStrategyEndDate(newValue)}
						/>

					</LocalizationProvider>


					<TextField
						className={gClasses.mb20}
						label="Выбрать стретегию"
						style={{width: 300}}
						variant="standard"
						select
						value={activeStrategy.name}
						onChange={onChangeTestStrategyName}
					>
						{strategies.map(st => <MenuItem key={st.name} value={st.name}>{st.labelName}</MenuItem>)}
					</TextField>

					<div className={classes.modalBtnsWrapper}>
						<Button onClick={onCloseModalTestStrategy}>Отмена</Button>
						<Button onClick={onStartTestStrategy}>Начать тестирование!</Button>

					</div>

				</div>
			</Modal>

			<CustomSnackbar toast={toast} setToast={setToast}/>

			<Backdrop
				sx={{ color: '#fff', zIndex: 100}}
				open={backdrop}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

		</>
	)
}

export default Layout
