import instance from "../api/axios.api";

const UIService = {

    async runTrainAI(options) {
        const { data } = await instance.post("/ui/run-train-ai", {...options});
        return data;
    },

    async runPredictAI(options) {
        const { data } = await instance.post("/ui/run-predict-ai", {...options});
        return data;
    },

    async generateDatasetToAi(options) {
        const { data } = await instance.post("/ui/generate-dataset-to-ai", {...options});
        return data;
    },

    async testStrategy(options) {
        const { data } = await instance.post("/ui/test-strategy", {...options});
        return data;
    },

    async saveTestItem(options) {
        const { data } = await instance.post("/ui/save-test-item", {...options});
        return data;
    },


};
export default UIService;
