import React from 'react';
import {makeStyles} from "@mui/styles";
import {
    orange,
    deepOrange,
    lightGreen,
    lightBlue,
    grey,
    red,
    yellow,
    deepPurple,
    green,
    blue,
    teal,
} from '@mui/material/colors';

const onePx = 0.0625;
export default makeStyles((theme) => ({

    //colors
    deepPurple500: {
        color: deepPurple[500],
    },
    colorOrange500: {
        color: orange[500],
    },
    colorOrange700: {
        color: orange[700],
    },
    colorRed500: {
        color: red[500],
    },
    lightGreen500: {
        color: lightGreen[500],
    },
    green500: {
        color: green[500],
    },
    lightBlue500: {
        color: lightBlue[500],
    },
    yellow500: {
        color: yellow[500],
    },
    blue200: {
        color: blue[200]
    },
    blue300: {
        color: blue[300]
    },
    blue400: {
        color: blue[400]
    },
    grey300: {
        color: grey[300],
    },
    grey400: {
        color: grey[400],
    },
    grey500: {
        color: grey[500],
    },
    lightGreen200: {
        color: lightGreen[200]
    },
    lightGreen300: {
        color: lightGreen[300]
    },
    lightGreen400: {
        color: lightGreen[400]
    },
    teal500: {
        color: teal[500],
    },
    cActive: {
        color: "#4CAF50!important",
    },

    //font-w
    fw400: {
        fontWeight: 400,
    },
    fw500: {
        fontWeight: 500,
    },
    fw600: {
        fontWeight: 600,
    },
    fw700: {
        fontWeight: 700,
    },
    m0: {
        margin: "0!important"
    },
    mt0: {
        marginTop: "0!important",
    },
    mb0: {
        marginBottom: "0!important",
    },
    mb5: {
        marginBottom: "5px!important",
    },
    mb10: {
        marginBottom: "10px!important",
    },
    mb16: {
        marginBottom: "16px!important",
    },
    mb20: {
        marginBottom: "20px!important",
    },
    mt10: {
        marginBottom: "10px!important",
    },
    mt15: {
        marginTop: "15px!important",
    },
    mt20: {
        marginTop: "20px!important",
    },
    ml0: {
        marginLeft: "0!important",
    },
    ml5: {
        marginLeft: "5px!important",
    },
    mr0: {
        marginRight: "0!important",
    },
    mr10: {
        marginRight: "10px!important",
    },
    mr16: {
        marginRight: "16px!important",
    },
    w90: {
        width: "90%",
    },
    w100: {
        width: "100%",
    },
    h100: {
        height: "100%",
    },
    p10: {
        padding: "10px!important",
    },
    p16: {
        padding: "16px!important",
    },
    p20: {
        padding: "20px!important",
    },
    pb70: {
        paddingBottom: 70,
    },
    pl16: {
        paddingLeft: 16,
    },
    noBg: {
        background: "none!important",
    },
    textH2: {
        fontWeight: 700,
    },
    h2: {
        fontWeight: 700,
        fontSize: 24*onePx+"rem",
        lineHeight: "28px",
        letterSpacing: "0.35px",
        color: "#000000",
        marginBottom: 10,
    },
    h3: {
        fontWeight: 600,
        fontSize: 16*onePx+"rem",
        lineHeight: "21px",
        color: "#2F2E36",
        marginBottom: 10,
    },
    defaultLink: {
        "color": "#00a151",
        "textDecoration": "underline",
        "cursor": "pointer",
    },

    wrapper: {
        background: "#fff",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },

    centerFlexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    loadingWrapper: {
        position: "fixed",
        width: "100%!important",
        height: "100%!important",
        left: 0,
        top: 0,
        display: "flex!important",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },

    appBar: {
        width: "100%",
        height: 50,
        //background: "#f5f5f9",
        background: "#fff",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 2px 6px rgba(166, 166, 166, 0.25)",
    },

    toolbar: {
        width: "100%",
        maxWidth: 720,
        minHeight: "auto",
        justifyContent: "space-between",
        paddingLeft: 16,
        paddingRight: 16,
    },

    toolbarLogo: {
        width: "100%",
        textAlign: "center",
        "& img": {
            height: 30,
        }
    },

    appContentwrapper: {
        overflow: "auto",
        width: "100%",
        flex: 1,
        height: "calc(100% - 50px)",
    },

    appContent: {
        maxWidth: 720,
        margin: "auto",
        background: "#fff",
        height: "calc(100% - 48px)",
        //minHeight: "100%",
        "& .MuiTabPanel-root": {
            padding: 0,
            height: "100%",
            position: "relative",
        }
    },
    appContentWithBottomPanel: {
        height: "calc(100% - 30px)",
        minHeight: "auto",
        overflowY: "auto",
    },
    appContentWithBottomPanel2: {
        height: "100%",
        minHeight: "auto",
        overflowY: "auto",
    },

    scrollBox: {
        overflow: "auto",
        width: "100%",
        height: "calc(100% - 56px)",
        padding: "0 16px",
        background: "#ebe9e9",
        maxWidth: 720,
        margin: "auto",
        "& .infinite-scroll-component ": {
            paddingBottom: 54,
        }
    },

    aoInput: {
        "& .MuiInput-input": {
            borderRadius: 8,
            border: "1px solid rgba(63, 85, 106, 0.28)",
            padding: 12,
            color: "#002033",
            fontSize: 15*onePx+"rem",
            fontWeight: 400,
            width: "100%",
        },
        "&::placeholder": {
            color: "rgba(16, 32, 47, 0.35)",
        },
    },
    drawerWrapper: {
        height: "100%",
    },
    drawerFrame: {
        width: "100%",
        maxWidth: 720,
        margin: "0 auto",
        height: "100%",
        background: "#fff",
    },
    fullPageDrawer: {
        "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
        },
    },

    drawer: {
        "& .MuiDrawer-paper": {
            borderRadius: "15px 15px 0 0",
            paddingTop: 25,
            background: "#fff",
            "&::before": {
                transition: "opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
                content: '""',
                position: "absolute",
                width: 36,
                height: 3,
                top: "10px",
                opacity: 1,
                left: "calc(50% - 18px)",
                background: "#d1d1d1",
                zIndex: 2,
            },
        },
    },
    drawerNotSw: {
        "& .MuiDrawer-paper": {
            borderRadius: "15px 15px 0 0",
            paddingTop: 25,
            background: "#fff",
        },
    },
    drawerNotSwCloseBtn: {
        position: "absolute",
        top: 7,
        right: 16,
        "color": "#006BE9",
        fontSize: "0.8rem",
    },
    drawerH30: {
        "& .MuiDrawer-paper": {
            height: "30%",
            overflow: "hidden",
        },
    },
    drawerH50: {
        "& .MuiDrawer-paper": {
            height: "50%",
            overflow: "hidden",
        },
    },
    drawerH75: {
        "& .MuiDrawer-paper": {
            height: "75%",
            overflow: "hidden",
        },
    },
    drawerSt: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            overflow: "hidden",
        },
    },
    drawerFs: {
        "& .MuiDrawer-paper": {
            height: "100%",
            overflow: "hidden",
        },
    },
    drawerFsGallery: {
        "& .MuiDrawer-paper": {
            height: "100%",
            borderRadius: 0,
            background: "#292929",
            overflow: "hidden",
        },
    },
    drawerNotAnim: {
        "& .MuiDrawer-paper": {
            height: "100%",
            overflow: "hidden",
        },
    },

    drawerUnderActive2Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            transform: "scale(0.92, 1.0)!important",
            overflow: "hidden",
            "&::before": {
                opacity: "0!important",
            }
        },
    },

    drawerUnderActive3Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 47px)",
            transform: "scale(0.92, 1.0) translate(0px, -17px)!important",
            overflow: "hidden",
            "&::before": {
                opacity: "0!important",
            }
        },
    },

    drawerNotVisible: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 30px)",
            overflow: "hidden",
            transform: "scale(0.86, 1.0) translate(0px, 17px)!important",
        },
    },

    drawerSt2Level: {
        "& .MuiDrawer-paper": {
            height: "calc(100% - 47px)",
            overflow: "hidden",
        },
    },
    drawerCatalogReCardHeader: {
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
        boxShadow: "0px 2px 6px rgba(166, 166, 166, 0.25)",
        background: "#fff",
    },
    drawerCatalogRemovedReHeader: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        paddingRight: 40,
        fontSize: 17,
        fontWeight: 600,
        color: "#2F2E36",
    },
    drawerCloseBtn: {
        color: "#657780",
    },
    drawerActionButtons: {
        "& .MuiButtonBase-root": {
            marginLeft: 8,
        }
    },
    contentWrapper: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative",
    },
    circularProgressWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        bottom: 20,
        left: 0
    }

}));