import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({

    toolbar: {
        minHeight: 42,
        height: 42,
    },
    mainWrapper: {
        height: "calc(100% - 42px)",
        background: "#191c27",
    },
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    modalWrapper: {
        padding: "50px 40px 20px 40px",
        display: "flex",
        justifyContent: "center",
        background: "#fff",
        flexDirection: "column",
    },
    modalBtnsWrapper: {
        marginTop: 50,
    },
    toolbarStrategyName: {

    },
    toolbarTestStrategyRangeWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiFormControl-root": {
            margin: "0 5px"
        },
        "& .MuiInputBase-input": {
            padding: "2px 7px",
        }
    }

}));