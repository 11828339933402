import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import User from "../pages/User/User";
import Chart from "../pages/Chart/Chart"

//загружает нужный компонент в зависимости от url
const Router = () => {
    return (
        <Routes>
            <Route path="/auth/login" element={<Auth type={"login"} />} />
            <Route path="/auth/register" element={<Auth type={"register"} />} />
            <Route path="/" element={<User/>}/>
            <Route path="/chart" element={<Chart/>} />
        </Routes>
        );
    };

export default Router;
