import instance from "../api/axios.api";
import { getTokens } from "../api/tokens.api";

const UserService = {

  async getProfile() {

    const { accessToken } = getTokens();

    if (accessToken) {
      try{
        const { data } = await instance.get("/user/profile");
        return {
          status: true,
          user: data,
        };
      }catch (e) {
        return {
          status: false,
          error: "Не удалось получить accessToken пользователя"
        };
      }

    }

    return {
      status: false,
      error: "Не удалось получить accessToken пользователя"
    };

  },

};

export default UserService;
