import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({

    rateInfoWrapper: {
        width: 500,
        padding: "10px 15px 0px 15px",
        marginBottom: -5,
        //backgroundColor: "#191c27",
        borderLeft: "2px solid #383e55",
    },

    chartWrapper: {
        display: "flex",
    },
    chart: {
        //backgroundColor: "#191c27"
    },
    rateInfoOptionsRow: {
        display: "flex",
        marginBottom: 10,
        justifyContent: "space-between",
        "& div": {
            fontSize: "13px",
            letterSpacing: "-0.24px",
        },
        "& div:first-child": {
            width: 250,
            marginRight: 10,
            fontWeight: 400,
            color: "#969696",
            //color: "#657780",
            position: "relative",
            "& span": {
                //background: "#fff",
                background: "#191c27",
                zIndex: 2,
                paddingRight: 10,
                position: "relative",
            },
        },

        "& div:first-child::after": {
            content: '""',
            left: 0,
            top: 11,
            height: 1,
            //borderBottom: "1px solid rgba(63, 85, 106, 0.28)",
            borderBottom: "1px solid #ffffff2b",
            width: "100%",
            position: "absolute",
        },
        "& div:nth-child(2)": {
            width: "calc(100% - 185px)",
            fontWeight: 500,
            //color: "#2F2E36",
            color: "#d4d4d4",
        },
        "& i": {
            color: "#6d6d6d",
            fontStyle: "normal",
        },
        "& p": {
            margin: 0,
            color: "#6d6d6d"
        },
        "& .active": {
            color: "#d4d4d4",
        }
    },

}));