import { useContext, useEffect } from "react"
import { UserContext } from "../providers/UserProvider";
import { useNavigate, useLocation } from "react-router-dom";


export const useUserRedirect = () => {

	const { user } = useContext(UserContext);

	const nav = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		if(!user) nav('/auth/login');
	}, [user, pathname]);

}