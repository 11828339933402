import React, { useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import UserService from "../services/user.service";
import { errorCatch } from "../utils/errorCatch";
import { toast } from "react-toastify";
//import { useLocation, useNavigate } from "react-router-dom";

//получает данные пользователя и сохраняет их в state UserContext
const AuthProvider = ({ children }) => {

    const { setUser, user, loggingIn, setLoggingIn } = useContext(UserContext);

    //проверяем только при загрузке
    useEffect(() => {

        if(user) return;

        UserService.getProfile().then((res, err) => {

            const error = (err || !(res && res.status && res.user)) && (res?.error || "Неизвестная ошибка получения профайла пользователя!");
            if(error) {
                setLoggingIn(false);
                return toast.error(errorCatch(res.error));
            }

            //если ошибок авторизации нет - сохраняем пользователя
            if( !(user && user._id && user._id == res.user._id)){
                setUser(res.user);
            }

            setLoggingIn(false);

            console.log("re-render AuthProvider useEffect загружен user _id= ", res.user._id);

        });


    },[user, loggingIn]);

  return <>{children}</>;
};

export default AuthProvider;
